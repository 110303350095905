var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ch-layout',{attrs:{"page":"","search":"","button":""},scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('div',{staticStyle:{"width":"100%"}},[_c('ch-search',{attrs:{"render-option":_vm.searchOption,"model":_vm.searchForm,"props":{ labelWidth: '66px'}}})],1)]},proxy:true},{key:"button",fn:function(){return [_c('ch-button',{style:({
                marginRight: '10px',
              }),on:{"click":_vm.batchReply}},[_vm._v(" 批量回复 ")]),_c('ch-button',{style:({
                marginRight: '10px',
              }),on:{"click":_vm.batchClose}},[_vm._v(" 批量关闭 ")]),_c('ch-button',{style:({
                marginRight: '10px',
              }),on:{"click":_vm.batchOpen}},[_vm._v(" 批量启用 ")]),_c('ch-button',{style:({
                marginRight: '10px',
              }),on:{"click":_vm.batchDelete}},[_vm._v(" 批量删除 ")])]},proxy:true},{key:"default",fn:function(){return [_c('ch-table',{attrs:{"render-option":_vm.tableOption,"data":_vm.tableList,"loading":_vm.isTableLoading,"props":{width: '100%', height: '100%', size: 'mini'}},on:{"selection-change":_vm.tableSelect},scopedSlots:_vm._u([{key:"handle",fn:function(scope){return [(scope.row.replyCount<1)?_c('ch-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.openReply(scope.row.id)}}},[_vm._v("回复")]):_vm._e(),_c('ch-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.openDetail(scope.row.id)}}},[_vm._v("查看")]),(scope.row.status===1)?_c('ch-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.editEvaluate(scope.row.id,0)}}},[_vm._v("启动")]):_vm._e(),(scope.row.status===0)?_c('ch-button',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.editEvaluate(scope.row.id,1)}}},[_vm._v("关闭")]):_vm._e(),_c('ch-button',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.handleDelete(scope.row.id)}}},[_vm._v("删除")])]}}])})]},proxy:true},{key:"page",fn:function(){return [_c('ch-page',{ref:"page",attrs:{"type":"old","sizeDe":20,"getListFunction":_vm.getTableList}})]},proxy:true}])}),_c('reply-modal',{ref:"replyModal",on:{"getListFunction":_vm.getTableList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }